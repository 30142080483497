@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Playfair+Display:wght@600&display=swap");


*{
  margin: 0;
  padding: 0;
}


/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e7e7; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
  
}